
import { Vue, Component, Prop } from 'vue-property-decorator';
import vClickOutside from 'v-click-outside';

@Component
export default class BaseInputPhone extends Vue {
  @Prop({ default: null }) maxLength!: number;
  @Prop({ default: '' }) placeholder!: string;
  @Prop({ default: '' }) description!: string;
  @Prop({ default: false }) error!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: '' }) errorMessage!: string;
  @Prop({ default: '' }) value!: string;
  @Prop({ default: '' }) countryCode!: string;
  @Prop({ default: '' }) label!: string;

  isInputClicked = false;

  onInput(event: Event): void {
    const target = event.target as HTMLInputElement;

    target.value = target.value.replace('+1', '');
    target.value = target.value.replace(/[^0-9]/g, '');
    target.value = target.value.slice(0, this.maxLength);
    this.$emit('input', this.countryCode + target.value);
  }

  checkField(): void {
    if (this.isInputClicked) this.$emit('checkField');
  }

  created(): void {
    Vue.use(vClickOutside);
  }
}
