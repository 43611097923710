
import { setBasicUserInfo } from '@/api/common';
// import { refreshUserCompany, refreshUserContact } from '@/api/hubSpot';
import { getFilteredIndustriesList, getFilteredLocationsList } from '@/api/industriesAndLocation';
import BaseButton from '@/components/baseComponents/BaseButton.vue';
import BaseInput from '@/components/baseComponents/BaseInput.vue';
import BaseInputPhone from '@/components/baseComponents/BaseInputPhone.vue';
import BaseCertificates from '@/components/baseComponents/BaseCertificates.vue';
import TitleDescriptionUniversal from '@/components/portal/TitleDescriptionUniversal.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    TitleDescriptionUniversal,
    BaseButton,
    BaseInput,
    BaseInputPhone,
    BaseCertificates,
  },
})
export default class NamePage extends Vue {
  isPreloaderShow = false;
  debounceMs = 500;
  debounceTimer: undefined | number = undefined;
  countryCode = '+1';
  errors = {
    firstName: false,
    lastName: false,
    companyName: false,
    phone: false,
  };

  get firstName(): string {
    return this.$store.state.user.firstName;
  }
  set firstName(value: string) {
    this.$store.commit('user/setFirstName', value);
  }
  get lastName(): string {
    return this.$store.state.user.lastName;
  }
  set lastName(value: string) {
    this.$store.commit('user/setLastName', value);
  }
  get companyName(): string {
    return this.$store.state.user.companyName;
  }
  set companyName(value: string) {
    this.$store.commit('user/setCompanyName', value);
  }
  get phone(): string {
    return this.$store.getters['user/phone'];
  }
  set phone(value: string) {
    this.$store.commit('user/setPhone', value);
  }
  get optionsIndustries(): any {
    return this.$store.state.user.optionsIndustries;
  }
  get optionsLocations(): any {
    return this.$store.state.user.optionsLocations;
  }
  get correctFields(): boolean {
    return (
      this.firstName.length >= 2
      && this.lastName.length >= 2
      && this.phone.length === 10
      && this.companyName.length >= 2
    );
  }
  get nextButtonText(): string {
    return this.correctFields ? 'Next' : 'All fields are required';
  }

  checkField(field: string): void {
    switch (field) {
      case 'firstName':
        this.errors.firstName = this.firstName.length < 2;
        break;
      case 'lastName':
        this.errors.lastName = this.lastName.length < 2;
        break;
      case 'phone':
        this.errors.phone = this.phone.length < 10;
        break;
      case 'company':
        this.errors.companyName = this.companyName.length < 2;
        break;
      default:
        break;
    }
  }
  async nextButtonAction(): Promise<void> {
    try {
      this.isPreloaderShow = true;
      await this.updateData();
      this.$store.commit('user/setIsRegistrationCompleted');
      this.$router.push({ name: 'PortalPage' });      
      // this.hubspotRequests();
    } catch (error: any) {
      this.popupError(error.response.data.message);
    } finally {
      this.isPreloaderShow = false;
    }
  }
  async updateData(): Promise<void> {
    await setBasicUserInfo({
      firstName: this.firstName,
      lastName: this.lastName,
      phone: this.countryCode + this.phone,
      companyName: this.companyName,
    });
  }
  async showFilteredIndustries(event: string): Promise<void> {
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(async () => {
      const filteredIndustries = event ? await getFilteredIndustriesList(event) : [];
      this.$store.commit('user/setOptionsIndustries', filteredIndustries);
    }, this.debounceMs);
  }
  async showFilteredLocations(event: string): Promise<void> {
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(async () => {
      const filteredLocations = event ? await getFilteredLocationsList(event) : [];
      this.$store.commit('user/setOptionsLocations', filteredLocations);
    }, this.debounceMs);
  }
  // async hubspotRequests(): Promise<void> {
  //   await refreshUserContact();
  //   refreshUserCompany();
  // }
  popupError(errorText: string): void {
    this.$notify({
      group: 'main-notification',
      type: 'error',
      text: errorText,
    });
  }
}
